import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class BorrowingToolsService {
    readQuery(){
        var query = `
            query ($paging:ServerPaging, $status:String) {
                GetTransactionProjectBorrowedTool (Paging:$paging, Status:$status) {
                    project_borrowed_tool {
                        created_at
                        last_update
                        project_borrowed_tool_id
                        project_id
                        project_name
                        project_code
                        pre_project_name
                        storage_name
                        storage_id
                        limit
                        created_by
                        invoiced_projects
                        invoiced_payment_projects
                        notes
                        status
                    }
                    total
                }
            }`;
        return query;
    }

    async GetProjectBorrowedToolDetail(id) {
        const variables = {
            id : id
        }
        var query = gql`
            query ($id:Int) {
                GetTransactionProjectBorrowedTool (ProjectBorrowedToolId:$id) {
                    project_borrowed_tool {
                        created_at
                        last_update
                        project_borrowed_tool_id
                        project_id
                        project_name
                        project_code
                        pre_project_name
                        storage_name
                        storage_id
                        limit
                        created_by
                        invoiced_projects
                        invoiced_payment_projects
                        notes
                        status
                        detail {
                            created_by
                            created_at
                            approved_by
                            last_update
                            project_borrowed_tool_id
                            project_borrowed_tool_detail_id
                            status
                            item_id
                            product_code
                            item_name
                            item_type
                            base_uom
                            uom
                            conv_amount
                            stock
                            quantity
                            notes
                        }
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetTransactionProjectBorrowedTool.project_borrowed_tool[0];
    }

    async GetDetailProject(id){
        const variables = {
            id : id
        }
        var query = gql`query ( $id:Int ) {
            GetTransactionProject( ProjectId:$id ){
                project {
                    created_at
                    last_update
                    project_id
                    project_code
                    project_name
                    sales_id
                    sales_name
                    status
                    notes
                    project_detail
                    invoiced_projects
                    invoiced_payment_projects
                }
                total
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetTransactionProject.project[0];
    }

    GetProjectDataQuery () {
        var query = `
            query ($paging:ServerPaging, $projecttype:Int, $status:[String]) {
                GetTransactionProject (Paging:$paging, ProjectType:$projecttype, Status:$status) {
                    project {
                        project_id
                        project_code
                        project_name
                        status
                        notes
                    }
                    total
                }
            }
        `;
        return query;
    }
    
    async GetStorageData(){
        var query = gql`
            query {
                GetStorage {
                    storage_id
                    storage_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var storageData = [];
        for (let i = 0; i < result.data.GetStorage.length; i++) {
            var label = result.data.GetStorage[i].storage_id + ' (' + result.data.GetStorage[i].storage_name + ')'
            var str = {
                value: result.data.GetStorage[i].storage_id, 
                label: label
            }
            storageData.push(str);
        }
        return storageData
    }
    
    GetItemDataQuery(){
        var query = `
            query ($id: String!, $paging: ServerPaging) {
                GetTransactionProjectBorrowedToolItemList (StorageId: $id, Paging: $paging) {
                    items {
                        created_at
                        last_update
                        status
                        created_by
                        item_id
                        product_code
                        item_name
                        item_type
                        base_uom
                        uom
                        conv_amount
                        stock
                        quantity
                        notes
                    }
                    total
                }
            }
        `;
        return query;
    }
    
    async getRoleFlagById(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int!){
            GetRole(RID:$id){
                rname
                role_flag{
                    flag_id,
                    flag_name,
                    flag_status
                }
            }
        }`;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetRole[0].role_flag;
    }

    selectedItemConverter(data){
        var newData = data.split(";");
        let array = [];
        for (let i = 0; i < newData.length; i++) {
            var splitData = newData[i].split("|");
            var str = {
                project_borrowed_tool_detail_id: null,
                item_id: parseInt(splitData[0]),
                product_code: splitData[1],
                item_name: splitData[2],
                uom: splitData[3],
                quantity: 0,
                notes: '',
                status: 'New',
                created_by: window.$cookies.get('ContactName'),
                created_at: moment(new Date()).format("YYYY-MM-DD"),
                approved_by: '',
            }
            array.push(str);
        }

        return array;
    }
    
    gridDataConverter(data){
        let array = [];
        for (let i = 0; i < data.length; i++) {
            var str = {
                project_borrowed_tool_detail_id: data[i].project_borrowed_tool_detail_id,
                item_id: data[i].item_id,
                product_code: data[i].product_code,
                item_name: data[i].item_name,
                uom: data[i].uom,
                quantity: data[i].quantity,
                notes: data[i].notes,
                status: data[i].status,
                created_by: data[i].created_by,
                created_at: data[i].created_at,
                approved_by: data[i].approved_by,
            }
            array.push(str);
        }
        return array;
    }
    
    approverDataConverter(data, newStatus){
        let array = [];
        var str = {
            project_borrowed_tool_detail_id: data.project_borrowed_tool_detail_id,
            item_id: data.item_id,
            uom: data.uom,
            quantity: data.quantity,
            notes: data.notes,
            status: newStatus,
        }
        array.push(str);

        return str;
    }

    async addQuery(variables){
        var query = gql`
            mutation($data: NewTransactionProjectBorrowedTool!){
                CreateTransactionProjectBorrowedTool(New: $data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        var query = gql`
        mutation($id: Int!, $data: NewTransactionProjectBorrowedTool! ){
            UpdateTransactionProjectBorrowedTool(ProjectBorrowedToolId: $id,  New: $data )
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async updateStatusQuery(variables){
        var query = gql`
        mutation($id:Int!, $data:NewTransactionProjectBorrowedToolDetail! ){
            UpdateTransactionProjectBorrowedToolDetailStatus(ProjectBorrowedToolDetailId:$id,  New:$data )
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}


export default new BorrowingToolsService();